import { _AuthApi } from "api/auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ShouldBeLogged = () => {
  let location = useLocation();
  if (!_AuthApi.getToken())
    return <Navigate to="/" state={location.pathname} replace />;
  return <Outlet />;
};

export default ShouldBeLogged;
