import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import LoginBg from "theme/LoginBg";
import { useTranslation } from "react-i18next";
import Input from "components/shared/Input";
import loginSchema from "validations/auth";
import { useMutation } from "react-query";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const { isLoading, mutate } = useMutation({
    mutationFn: (body) => _AuthApi.login(body),
    onSuccess: (data) => {
      localStorage.setItem("access_token", data.access_token);
      navigate("/dashboard");
    },
  });
  const onSubmit = (input) => {
    mutate(input);
  };

  return (
    <LoginBg>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          flexDirection: "column",
          backdropFilter: "bluer(20px)",
        }}
      >
        <BoxStyled
          sx={{
            minWidth: "400px",
            width: "30%",
            padding: "40px",
            backgroundColor: "transparent",
            border: "1px solid rgba(255,255,255,0.2)",
            backdropFilter: "blur(3px)",
          }}
        >
          <Box textAlign="center">
            <Typography variant="h5" sx={{ color: "origin.main" }}>
              {t("auth.welcome")}
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "lightGray.main", mt: "10px" }}
            >
              {t("auth.subtitle")}
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="auth.email"
              placeholder="auth.emailPlaceholder"
              errors={errors?.email?.message}
              {...register("email")}
            />
            <Input
              label="auth.password"
              placeholder="auth.passwordPlaceholder"
              type="password"
              errors={errors?.password?.message}
              {...register("password")}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "30px",
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "origin.main",
                  color: "#333",
                  "&:hover": {
                    backgroundColor: "#63d2d9",
                    transform: "scale(1.02)",
                  },
                  "&:disabled": {
                    backgroundColor: "#63d2d9",
                  },
                }}
                disabled={isLoading}
                fullWidth
                type="submit"
                variant="contained"
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "#333" }} size="25px" />
                ) : (
                  t("auth.signIn")
                )}
              </Button>
            </Box>
            <Box
              sx={{
                margin: "20px 0 8px 5px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Link to="/reset-password">
                <Typography variant="inputTitle">
                  {t("auth.resetPassword")}
                </Typography>
              </Link>
            </Box>
          </Box>
        </BoxStyled>
      </Box>
    </LoginBg>
  );
};

export default Login;
