import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { TextFieldStyled } from "components/styled/TextField";
import { useState } from "react";
import { useId } from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Input = forwardRef(
  ({ errors, label, placeholder, type = "text", ...props }, ref) => {
    const { t } = useTranslation("index");
    const isError = Boolean(errors)
    const [showPassword, setShowPassword] = useState(false);
    const id = useId();
    return (
      <Box sx={{ width: "100%", mt: "20px", flex:"1 1 0%" }}>
        <Typography
          sx={{ margin: "0 0 8px 5px", display: "inline-block", width: "100%" }}
          component="label"
          htmlFor={id}
          variant="inputTitle"
        >
          {t(label)}
        </Typography>
        <TextFieldStyled
          sx={{
            width: "100%",
            "& .MuiInputBase-input": { color: "origin.main" },
          }}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          ref={ref}
          {...props}
          id={id}
          placeholder={t(placeholder)}
          error={isError}
          helperText={t(errors)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {type === "password" ? (
                  <IconButton sx={{ background: "transparent", color:"origin.main" }} onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }
);

export default Input;
