import { _axios } from "interceptor/http-config";

export const _AuthApi = {
  login: async (body) => {
    const { data } = await _axios.post("/admin/login", body);
    return data;
  },

  storeToken: (access_token) => {
    localStorage.setItem("access_token", access_token);
  },

  getToken: () => localStorage.getItem("access_token"),

  destroyToken: () => {
    localStorage.removeItem("access_token");
  },

  resetPass: (data) => {
    return _axios.post("/admin/reset-password", data);
  },
  verifyCode: async (data) => {
    return await _axios.post("/admin/check-code", data);
  },
  passEdit: (data) => {
    return _axios.post("/admin/edit-password", data);
  },
};
