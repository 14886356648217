import * as yup from "yup";
const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("This must be a valid email")
    .required("Email Name is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The password is short, must be more than five characters")
    .max(20, "The password is long and must not exceed 20 characters."), 
});

export default loginSchema;
