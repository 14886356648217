import { _AuthApi } from "api/auth";
import { _axios as Axios } from "../interceptor/http-config";

export const HttpResponseInterceptor = (navigate, enqueueSnackbar) => {
  Axios.interceptors.response.use(
    function (response) {
      if (
        response?.config?.method !== "get" &&
        response?.config?.method !== "delete"
      ) {
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      }
      if (response?.config?.method === "delete") {
        enqueueSnackbar("Deleted successfully", {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      }
      return response;
    },
    function (error) {
      if (!error.response) {
        enqueueSnackbar("error", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      } else {
        switch (error.response.status) {
          case 405:
            Object.keys(error.response.data.message).map((key) =>
              enqueueSnackbar(error.response.data.message[key], {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              })
            );
            break;
          case 401:
            navigate("/");
            _AuthApi.destroyToken();
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
            break;
          case 450:
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
            break;
          case 403:
            enqueueSnackbar("Forbidden", {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
            break;
          case 422:
            if (error.response.data.message) {
              Object.keys(error.response.data.message).forEach((field) => {
                error.response.data.message[field].forEach((errorMessage) => {
                  enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 3000,
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  });
                });
              });
            } else if (error.response.data.errors) {
              enqueueSnackbar(error.response.data.message, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            }
            break;
          default:
            enqueueSnackbar(error.response?.data?.message, {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
            break;
        }
      }
      return Promise.reject(error);
    }
  );
};
