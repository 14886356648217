import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import LoginBg from "theme/LoginBg";
import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
import Input from "components/shared/Input";
import { useMutation } from "react-query";
let schema = yup.object().shape({
  email: yup
    .string()
    .email("This must be a valid email")
    .required("Email is required"),
});
const ResetPassword = () => {
  const { t } = useTranslation("index");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { isLoading, mutate } = useMutation({
    mutationFn: (body) => _AuthApi.resetPass(body),
    onSuccess: () => {
      navigate(`/reset-password/check-code/${getValues("email")}`, {
        replace: true,
      });
    },
  });
  const onSubmit = async (e) => {
    mutate(e);
  };

  return (
    <LoginBg>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          flexDirection: "column",
          backdropFilter: "bluer(20px)",
        }}
      >
        <BoxStyled
          sx={{
            minWidth: "400px",
            width: "30%",
            padding: "40px",
            backgroundColor: "transparent",
            backdropFilter: "blur(3px)",
            border: "1px solid rgba(255,255,255,0.2)",
          }}
        >
          <Box textAlign="center">
            <Typography variant="h5" sx={{ color: "origin.main" }}>
              {t("auth.resetPassword")}
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "lightGray.main", mt: "10px" }}
            >
              {t("auth.subtitle")}
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="auth.email"
              placeholder="auth.emailPlaceholder"
              errors={errors?.email?.message}
              {...register("email")}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "30px",
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "origin.main",
                  color: "#333",
                  "&:hover": {
                    backgroundColor: "#63d2d9",
                    transform: "scale(1.02)",
                  },
                  "&:disabled": {
                    backgroundColor: "#63d2d9",
                  },
                }}
                disabled={isLoading}
                fullWidth
                type="submit"
                variant="contained"
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "#333" }} size="25px" />
                ) : (
                  t("auth.sendCode")
                )}
              </Button>
            </Box>
          </Box>
        </BoxStyled>
      </Box>
    </LoginBg>
  );
};

export default ResetPassword;
