import loadable from "@loadable/component";
import ReactQueryProvider from "components/ReactQueryProvider";
import Loader from "components/shared/Loader";
import ShouldBeLogged from "middlewares/ShouldBeLogged";
import ShouldNotBeLogged from "middlewares/ShouldNotBeLogged";
import Login from "pages/Login";
import PasswordEditForm from "pages/PasswordEditForm";
import ResetPassword from "pages/ResetPassword";
import VerificationCodeForm from "pages/VerificationCodeForm";
import { Suspense } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, Routes } from "react-router-dom";

const DashboardRouting = loadable(() =>
  import("./modules/dashboard/DashboardRouting")
);

const AppRouting = () => {
  return (
    <Routes>
      <Route element={<ReactQueryProvider />}>
        <Route element={<ShouldNotBeLogged />}>
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password/check-code/:email"
            element={<VerificationCodeForm />}
          />
          <Route
            path="/reset-password/edit-password/:email/:code"
            element={<PasswordEditForm />}
          />
        </Route>
        <Route element={<ShouldBeLogged />}>
          <Route
            path="Dashboard/*"
            element={
              <Suspense fallback={<Loader />}>
                <DashboardRouting />
                <ReactQueryDevtools initialIsOpen={false} />
              </Suspense>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouting;
